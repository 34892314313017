/** 筛选条件模块 */ 
<template>
  <section class="filters-content back-fff mb-10">
    <div class="content">
      <ul class="filters-ul" ref="ulParent">
        <li class="filters-li" v-for="(item,index) in filtersArr" :key="index" :ref="item.type">
          <label class="filters-label pt-7">{{item.label}}：</label>
          <div class="filters-items">
            <el-button type="text" :class="[{'theme-back':isActive(filter)}, 'pa-7','ma-8']"
              v-for="(filter,ind) in item.value" :key="ind" @click="filterBtn(item.type,filter)">
              <template>{{filter.nc_cn?filter.nc_cn:filter.value}}</template>
              <!-- 数量 -->
              <template v-if="filter.count"> ({{filter.count}}) </template>
            </el-button>
          </div>
          <el-button v-show="item.showUnFold" size="small" class="filters-actions filters-unfold theme-border"
            @click="unfold(item.type)">
            + 展开
          </el-button>
          <el-button v-show="false" size="small" class="filters-actions filters-fold theme-border"
            @click="fold(item.type)">
            - 折叠
          </el-button>
        </li>
        <!-- 已选条件 -->
        <li v-if="selectFilter.value.length" class="filters-li" :ref="selectFilter.type">
          <label class="filters-label pt-7">{{selectFilter.label}}：</label>
          <div class="filters-items">
            <!-- <template v-for="(item,index) in selectFilter.value"> -->
            <el-tag class="pa-7 ma-8" v-for="(filter,ind) in selectFilter.value" :key="ind"
              @close="filterBtn(filter.type,filter)" closable effect="plain">
              <template>{{filter.nc_cn?filter.nc_cn:filter.value}}</template>
            </el-tag>
            <el-button type="text" class="clear-all " v-if="selectFilter.value.length>1" @click="clearAll">清除全部
            </el-button>
            <!-- </template> -->
          </div>
          <el-button v-show="selectFilter.showUnFold" size="small" class="filters-actions filters-unfold theme-border"
            @click="unfold(selectFilter.type)">
            + 展开
          </el-button>
          <el-button v-show="selectFilter.showFold" size="small" class="filters-actions filters-fold theme-border"
            @click="fold(selectFilter.type)">
            - 折叠
          </el-button>
        </li>
      </ul>
    </div>
  </section>
</template>
<script>
export default {
  name: 'Filters',
  props: ['filters'],
  data () {
    return {
      filtersArr: [],
      selectFilter: {
        type: 'selectFilter',
        showUnFold: false,
        showFold: false,
        label: '已选条件',
        value: []
      },// 前端展示格式
      selectDomHeight: 0,// 已选条件的dom高度
      selectObj: {},//传递后台格式
    }
  },
  mounted () {
    this.setFiltersData()
    this.isFilterUnfold()
  },
  methods: {
    setLabel (key) {
      let label;
      switch (key) {
        // 商标
        case 'cs':
          label = '法律状态'
          break;
        case 'fy':
          label = '申请时间'
          break;
        case 'hno':
          label = '申请人'
          break;
        case 'nc':
          label = '国际分类'
          break;

        // 专利
        case 'pdt':
          label = '专利类型'
          break;
        case 'lsscn':
          label = '法律状态'
          break;
        case 'apos':
          label = '申请人'
          break;
        case 'ad':
          label = '申请时间'
          break;
        case 'rd':
          label = '剩余年限'
          break;
        default:
          break;
      }
      return label
    },
    // 整合筛选条件数据
    setFiltersData () {
      for (const key in this.filters) {
        // 时间截取前四位  2021/01/01--->2021
        if (key == 'fy' || key == 'ad') {
          this.filters[key] = this.filters[key].map(item => {
            return { count: item.count, value: item.value.substring(0, 4) }
          })
          this.filters[key].sort(this.$utils.compare('value'))//排序-倒叙
        }
        if (key == 'nc') {
          this.filters[key].sort(this.$utils.compare('value', '+'))//排序-正序
        }
        this.filtersArr.push({ type: key, showUnFold: false, label: this.setLabel(key), value: this.filters[key] })
      }
    },
    // 判断筛选条件是否需要展开
    isFilterUnfold () {
      this.$nextTick(() => {
        this.filtersArr.forEach(i => {
          i.showUnFold = this.isUnfold(i.type);
        })
      })
    },
    // 判断已选条件是否需要展开
    isSelectUnfold () {
      if (this.selectFilter.value.length) {
        this.isUnfold(this.selectFilter.type)
      }
    },

    // 判断是否需展开逻辑
    isUnfold (ref) {
      let liDom = (this.$refs[ref] instanceof Array) ? this.$refs[ref][0] : this.$refs[ref]
      let filtersHeight = liDom.getElementsByClassName('filters-items')[0].offsetHeight
      // 内容盒子高度>父盒子固定的高度，就显示展开/折叠按钮；反之，隐藏
      if (ref == this.selectFilter.type) {
        this.selectDomHeight = filtersHeight
        this.unfold(this.selectFilter.type)
      } else {
        if (filtersHeight >= liDom.offsetHeight) {
          return true
        } else {
          return false
        }
      }
    },

    // 判断是否已选中
    isActive (filter) {
      let selectFilter = this.selectFilter.value.find(i => {
        if (filter.nc_cn) {
          return i.nc_cn === filter.nc_cn
        } else {
          return i.value === filter.value
        }
      })
      if (selectFilter) {
        return true
      } else {
        return false
      }
    },
    // 点击筛选条件，存储/删除--已选条件
    filterBtn (type, filter) {
      let selectFilters = this.selectFilter.value
      let selectObjs = this.selectObj
      // 判断是否存在已选条件数据中
      let selectInd = selectFilters.findIndex(item => {
        if (filter.nc_cn) {
          return item.nc_cn === filter.nc_cn
        } else {
          return item.value === filter.value
        }
      })
      if (selectInd !== -1) {
        selectFilters.splice(selectInd, 1)// 前端展示数据删除
        selectObjs[type.toUpperCase()].remove(filter.value) // 传递后台数据删除
        if (!selectObjs[type.toUpperCase()].length) delete selectObjs[type.toUpperCase()]
      } else {
        // 存入到已选条件数据中
        selectFilters.push({ type, ...filter })
        // 判断对象中是否存在当前类别，存在，直接存入；反之，创建为数组再存入
        selectObjs[type.toUpperCase()] ? selectObjs[type.toUpperCase()].push(filter.value) : selectObjs[type.toUpperCase()] = [filter.value]
      }
      this.$emit('selectFilter', this.selectObj)
      setTimeout(() => {
        this.isSelectUnfold()
      }, 350);
    },

    // 清除全部已选条件
    clearAll () {
      this.selectFilter.value = []
      this.selectObj = {}
      this.selectFilter.showUnFold = false
      this.$emit('selectFilter', this.selectObj)
    },

    // 展开
    unfold (ref) {
      this.$nextTick(() => {
        let liDom = (this.$refs[ref] instanceof Array) ? this.$refs[ref][0] : this.$refs[ref]
        let filtersHeight = liDom.getElementsByClassName('filters-items')[0].offsetHeight
        liDom.style.height = `${filtersHeight}px`;
        liDom.getElementsByClassName('filters-unfold')[0].style.display = "none"
        if (ref == this.selectFilter.type && this.selectDomHeight <= 46) {
          liDom.getElementsByClassName('filters-fold')[0].style.display = "none"
        } else {
          liDom.getElementsByClassName('filters-fold')[0].style.display = "block"
        }
      })
    },
    // 折叠
    fold (ref) {
      let liDom = (this.$refs[ref] instanceof Array) ? this.$refs[ref][0] : this.$refs[ref]
      liDom.style.height = 46 + "px";
      liDom.getElementsByClassName('filters-unfold')[0].style.display = "block"
      liDom.getElementsByClassName('filters-fold')[0].style.display = "none"
    }
  },
}
</script>
<style lang="scss" scoped>
.filters-content {
  padding: 10px 0;
}
.filters-li {
  height: 48px;
  overflow: hidden;
  position: relative;
  .filters-label {
    min-width: 80px;
    float: left;
    margin-top: 8px;
  }
  .filters-items {
    float: left;
    max-width: 84%;
    .el-button--text {
      color: #000000;
      border: none;
      &:hover {
        color: #2974ef;
      }
    }
    .el-tag {
      height: 30px;
      line-height: 0;
      font-size: 16px;
      ::v-deep .el-tag__close {
        font-size: 16px;
        font-weight: bold;
        right: -1px;
      }
    }
    .clear-all {
      font-size: 14px;
      color: #999;
      padding: 8px;
      margin: 8px 0 8px 8px;
    }
  }
  .filters-actions {
    height: 26px;
    padding: 7px 12px;
    position: absolute;
    right: 0;
    &:focus {
      border-color: #2974ef;
    }
    &.filters-unfold {
      top: 11px;
    }
    &.filters-fold {
      bottom: 11px;
    }
  }
}
</style>