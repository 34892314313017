/** 专利、商标列表 */
<template>
  <section class="back-fff">
    <div class="content pb-140">
      <div class="list-title ptb-32">
        <div class="justify-between-center">
          <span>共检测到{{ total }}个相关{{
              assetsType == "patent" ? "专利" : "商标"
            }}</span>
          <p class="list-actions">
            <!-- <el-button type="text" class="pa-0" @click="isBatch=!isBatch">批量操作</el-button> -->
            <el-dropdown class="ml-32" @command="handleCommand">
              <el-button type="text"> 排序 </el-button>
              <!-- 专利排序 -->
              <el-dropdown-menu slot="dropdown" v-if="assetsType == 'patent'">
                <el-dropdown-item command="-AD" :class="{ 'theme-back': assetsSort == '-AD' }">申请时间降序</el-dropdown-item>
                <el-dropdown-item command="-PD" :class="{ 'theme-back': assetsSort == '-PD' }">公开时间降序</el-dropdown-item>
              </el-dropdown-menu>
              <!-- 商标排序 -->
              <el-dropdown-menu slot="dropdown" v-else>
                <el-dropdown-item command="-FD" :class="{ 'theme-back': assetsSort == '-FD' }">申请时间降序</el-dropdown-item>
                <el-dropdown-item command="-RD" :class="{ 'theme-back': assetsSort == '-RD' }">公开时间降序</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </p>
        </div>
        <el-collapse-transition>
          <div v-show="isBatch">
            <div class="batch-operation justify-between-center pa-16">
              <el-checkbox ref="checkAll" v-model="checkAll" :indeterminate="isIndeterminate" @change="handleCheckAll">
                全选本页
              </el-checkbox>
              <el-button type="text" @click="monitorAllBtn">批量监控</el-button>
            </div>
          </div>
        </el-collapse-transition>
      </div>
      <ul v-if="total > 0" class="assets-list">
        <li class="list-li mb-10 pa-16 bax-shadow justify-between-center" v-for="(item, index) in listData"
          :key="index">
          <el-checkbox v-if="isBatch" class="align-center" ref="checkLi" @change="handleCheck($event, item.tid)">
          </el-checkbox>
          <slot :item="item"></slot>
          <div class="ml-32">
            <el-button class="monitor-btn" :loading="item.monitor_load" @click="monitorBtn(item, index)">
              {{
                !$store.getters.userToken ||
                (!item.associate_patent_status &&
                  !item.associate_trademark_status)
                  ? assetsType == "patent"
                    ? "专利监控"
                    : "监控"
                  : assetsType == "patent"
                  ? "取消专利监控"
                  : "取消监控"
              }}
            </el-button>
            <br />
            <el-button v-if="assetsType == 'patent'" class="monitor-btn monitor-annualCost-btn mt-10"
              :loading="item.monitor_annualCost_load" @click="monitorAnnualCostBtn(item, index)">
              {{
                !$store.getters.userToken || !item.associate_annualfee_status
                  ? "年费监控"
                  : "取消年费监控"
              }}</el-button>
          </div>
        </li>
      </ul>
      <el-empty v-else description="对不起，暂未查询到相关信息，请重新调整关键词，重新搜索"></el-empty>
      <Page :total="total" v-bind="$attrs" v-on="$listeners" />
    </div>
  </section>
</template>
<script>
import Page from "@/components/Page.vue";
import { setTradeMonitor } from "@/api/trademark";
import {
  setPatentMonitor,
  setPatentMonitorAnnualCost,
  delPatentMonitorAnnualCost,
} from "@/api/patent";
export default {
  name: "AssetsList",
  components: {
    Page,
  },
  props: {
    assetsType: String,
    listData: {
      typeof: Array,
      default: [],
    },
    total: {
      default: 0,
    },
  },
  data () {
    return {
      assetsSort: this.assetsType == "patent" ? "-AD" : "-FD", //排序
      isBatch: false, //批量操作
      checkAll: false, // 全选状态
      isIndeterminate: false, // 全选样式
      checkedCities: [], // 多选值
    };
  },
  watch: {
    isBatch () {
      this.checkAll = false;
      this.isIndeterminate = false;
      this.checkedCities = [];
    },
  },
  methods: {
    // 排序
    handleCommand (command) {
      this.assetsSort = command;
      this.$emit("setAssetsSort", command);
    },
    // 全选
    handleCheckAll (val) {
      this.checkedCities = [];
      if (val) {
        this.listData.forEach((i) => {
          this.checkedCities.push(this.assetsType == "patent" ? i.pid : i.tid);
        });
      }
      this.isIndeterminate = false;
      // 修改单选框样式状态
      this.$refs.checkLi.forEach((i) => {
        i.$el.getElementsByTagName("input")[0].checked = val;
        if (val) {
          i.$el
            .getElementsByClassName("el-checkbox__input")[0]
            .classList.add("is-checked");
        } else {
          i.$el
            .getElementsByClassName("el-checkbox__input")[0]
            .classList.remove("is-checked");
        }
      });
    },
    // 单选
    handleCheck (e, val) {
      if (e) {
        this.checkedCities.push(val);
      } else {
        this.checkedCities.splice(this.checkedCities.indexOf(val), 1);
      }
      // 修改全选框样式状态
      this.isIndeterminate =
        this.checkedCities.length &&
          this.checkedCities.length < this.listData.length
          ? true
          : false;
      this.checkAll =
        this.checkedCities.length >= this.listData.length ? true : false;
    },
    // 单个监控
    async monitorBtn (item, ind) {
      if (!this.$store.getters.userToken)
        return this.$message.warning("请先登录");
      this.listData[ind].monitor_load = true;
      try {
        // 判断请求专利监控 或 商标监控
        let { status } =
          this.assetsType == "patent"
            ? await setPatentMonitor({keywords:item.pid,monitor_status:this.listData[ind].associate_patent_status?2:1})
            : await setTradeMonitor({keywords:item.tid,monitor_status:this.listData[ind].associate_trademark_status?2:1});
        if (status == 200) {
          if (this.assetsType == "patent") {
            this.listData[ind].associate_patent_status =
              !this.listData[ind].associate_patent_status;
            this.$message.success(
              this.listData[ind].associate_patent_status
                ? "专利监控成功"
                : "取消专利监控成功"
            );
            if (
              this.listData[ind].associate_patent_status
              && this.listData[ind].ano.substr(0, 2).toUpperCase() == 'CN'
              && this.listData[ind].lssc == '1'
            ) {
              this.listData[ind].associate_annualfee_status = true;
            }
          } else {
            this.listData[ind].associate_trademark_status =
              !this.listData[ind].associate_trademark_status;
            this.$message.success(
              this.listData[ind].associate_trademark_status
                ? "监控成功"
                : "取消监控成功"
            );
          }
        } else {
          if (this.assetsType == "patent") {
            this.$message.error(
              this.listData[ind].associate_patent_status
                ? "取消专利监控失败"
                : "专利监控失败"
            );
          } else {
            this.$message.error(
              this.listData[ind].associate_trademark_status
                ? "取消监控失败"
                : "监控失败"
            );
          }
        }
        this.listData[ind].monitor_load = false;
      } catch (error) {
        this.listData[ind].monitor_load = false;
      }
    },
    // 年费监控
    async monitorAnnualCostBtn (item, ind) {
      if (!this.$store.getters.userToken)
        return this.$message.warning("请先登录");
      this.listData[ind].monitor_annualCost_load = true;
      try {
        let { status } = this.listData[ind].associate_annualfee_status
          ? await delPatentMonitorAnnualCost({keywords:item.ano,monitor_status: 2})
          : await setPatentMonitorAnnualCost({keywords:item.ano,monitor_status: 1});
        if (status == 200) {
          this.listData[ind].associate_annualfee_status =
            !this.listData[ind].associate_annualfee_status;
          this.$message.success(
            this.listData[ind].associate_annualfee_status
              ? "年费监控成功"
              : "取消年费监控成功"
          );
        } else {
          this.$message.error(
            this.listData[ind].associate_annualfee_status
              ? "取消年费监控失败"
              : "仅可监控国内有效专利"
          );
        }
        this.listData[ind].monitor_annualCost_load = false;
      } catch (error) {
        this.listData[ind].monitor_annualCost_load = false;
      }
    },
    // 批量监控
    monitorAllBtn () {
      if (this.checkedCities.length) {
        console.log(this.checkedCities);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.list-title {
  .batch-operation {
    background-color: #f0f2f5;
  }
}
::v-deep .assets-list {
  .list-li {
    .el-checkbox:last-of-type {
      margin-right: 22px;
    }
    .assets-img {
      width: 130px;
      height: 140px;
    }
    .assets-info {
      width: 0;
      .assets-title {
        width: 90%;
        span {
          line-height: 18px;
        }
        .el-tag {
          height: 24px;
          line-height: 24px;
        }
      }
      .el-col {
        margin: 5px 0;
      }
      .el-col,
      .assets-text {
        font-size: 14px;
        color: #1a1a1a;
      }
    }
    .monitor-btn {
      width: 100%;
    }
  }
}
</style>